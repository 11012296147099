<ng-container [ngSwitch]="popoverType">
  <button *ngSwitchCase="'repeating-item'" id="add-{{item._elementId}}" type="button" class="lhc-float-button lhc-button"
          nz-popover
          [disabled]="item._readOnly"
          [(nzPopoverVisible)]="item._showUnusedItemWarning"
          [nzPopoverContent]="warningStrTemplate"
          nzPopoverPlacement="topLeft"
          nzPopoverTrigger="click"
          (click)="lhcDataService.addOneRepeatingItem(item, append)"
          (blur)="lhcDataService.hideUnusedItemWarning(item)"
          attr.aria-controls="add-button-{{item._elementId}}"
          attr.aria-label="add button for {{item.question}}"
          attr.aria-describedby="add-button-{{ item._elementId }}">
    {{ buttonLabel }}
  </button>
  <button *ngSwitchCase="'help-string'" id="help-button-{{ item._elementId }}" type="button" class="lhc-help-button"
          nz-popover
          [nzPopoverContent]="helpStrTemplate"
          nzPopoverTrigger="click"
          nzPopoverPlacement="right"
          nzPopoverTitle="Instruction"
          nzPopoverOverlayClassName="lhc-popover-help"
          (click)="onShowingPopover(item)"
          attr.aria-controls="help-content-{{item._elementId}}"
          attr.aria-label="Help button for {{item.question}}"
          attr.aria-describedby="help-button-{{ item._elementId }}">
    <i nz-icon nzType="question-circle" nzTheme="fill"></i>
  </button>
  <button *ngSwitchCase="'help-html'" id="help-button-{{ item._elementId }}" type="button" class="lhc-help-button"
          nz-popover
          [nzPopoverContent]="helpHtmlTemplate"
          nzPopoverTrigger="click"
          nzPopoverPlacement="right"
          nzPopoverTitle="Instruction"
          nzPopoverOverlayClassName="lhc-popover-help"
          (click)="onShowingPopover(item)"
          attr.aria-controls="help-content-{{item._elementId}}"
          attr.aria-label="Help button for {{item.question}}"
          attr.aria-describedby="help-button-{{ item._elementId }}">
    <i nz-icon nzType="question-circle" nzTheme="fill"></i>
  </button>
  <button *ngSwitchCase="'help-plain'" id="help-button-{{ item._elementId }}" type="button" class="lhc-help-button"
          nz-popover
          [nzPopoverContent]="helpPlainTemplate"
          nzPopoverTrigger="click"
          nzPopoverPlacement="right"
          nzPopoverTitle="Instruction"
          nzPopoverOverlayClassName="lhc-popover-help"
          (click)="onShowingPopover(item)"
          attr.aria-controls="help-content-{{item._elementId}}"
          attr.aria-label="Help button for {{item.question}}"
          attr.aria-describedby="help-button-{{ item._elementId }}">
    <i nz-icon nzType="question-circle" nzTheme="fill"></i>
  </button>
  <button *ngSwitchCase="'legal-string'" id="legal-button-{{ item._elementId }}" type="button" class="lhc-legal-button"
          nz-popover
          [nzPopoverContent]="legalStrTemplate"
          nzPopoverTrigger="click"
          nzPopoverPlacement="right"
          nzPopoverTitle="Legal"
          nzPopoverOverlayClassName="lhc-popover-legal"
          (click)="onShowingPopover(item)"
          attr.aria-controls="legal-content-{{item._elementId}}"
          attr.aria-label="Legal button for {{item.question}}"
          attr.aria-describedby="legal-button-{{ item._elementId }}">
    <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  </button>
  <button *ngSwitchCase="'legal-html'" id="legal-button-{{ item._elementId }}" type="button" class="lhc-legal-button"
          nz-popover
          [nzPopoverContent]="legalHtmlTemplate"
          nzPopoverTrigger="click"
          nzPopoverPlacement="right"
          nzPopoverTitle="Legal"
          nzPopoverOverlayClassName="lhc-popover-legal"
          (click)="onShowingPopover(item)"
          attr.aria-controls="legal-content-{{item._elementId}}"
          attr.aria-label="Legal button for {{item.question}}"
          attr.aria-describedby="legal-button-{{ item._elementId }}">
    <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  </button>
  <button *ngSwitchCase="'legal-plain'" id="legal-button-{{ item._elementId }}" type="button" class="lhc-legal-button"
          nz-popover
          [nzPopoverContent]="legalPlainTemplate"
          nzPopoverTrigger="click"
          nzPopoverPlacement="right"
          nzPopoverTitle="Legal"
          nzPopoverOverlayClassName="lhc-popover-legal"
          (click)="onShowingPopover(item)"
          attr.aria-controls="legal-content-{{item._elementId}}"
          attr.aria-label="Legal button for {{item.question}}"
          attr.aria-describedby="legal-button-{{ item._elementId }}">
    <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  </button>
  <ng-container *ngSwitchCase="'copyright-string'">
    <button *ngIf="formLevel"
            id="copyright-button-{{popoverIdentifier}}"
            type="button"
            class="lhc-copyright-button"
            nz-popover
            [nzPopoverContent]="copyrightStrTemplate"
            nzPopoverTrigger="click"
            nzPopoverPlacement="right"
            nzPopoverTitle="Copyright"
            nzPopoverOverlayClassName="lhc-popover-copyright"
            (click)="onShowingPopover(item)"
            attr.aria-controls="copyright-content-{{popoverIdentifier}}"
            attr.aria-label="Copyright button for {{item.name || item.shortName}}"
            attr.aria-describedby="copyright-button-{{popoverIdentifier}}">
      <i nz-icon nzType="copyright-circle" nzTheme="outline"></i>
    </button>
  </ng-container>

</ng-container>

<!-- unused item warning template -->
<ng-template #warningStrTemplate>
  <span attr.aria-labelledby="add-button-{{item._elementId}}"
        role="region"
        attr.aria-label="{{item._unusedItemWarning}}"
        attr.aria-describedby="add-content-{{item._elementId}}"
        id="add-content-{{item._elementId}}"
  >{{item._unusedItemWarning}}</span>
</ng-template>

<!-- help popover template with trusted html -->
<ng-template #helpHtmlTemplate>
  <span attr.aria-labelledby="help-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Instruction for {{item.question}}"
        attr.aria-describedby="help-content-{{item._elementId}}"
        id="help-content-{{item._elementId}}"
        [innerHTML]="item._codingInstructionsWithContainedImages || item.codingInstructions | safeHtml"
  ></span>
</ng-template>
<!-- help popover template with plain text-->
<ng-template #helpPlainTemplate>
  <span attr.aria-labelledby="help-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Instruction for {{item.question}}"
        attr.aria-describedby="help-content-{{item._elementId}}"
        id="help-content-{{item._elementId}}"
  >{{item.codingInstructionsPlain}}</span>
</ng-template>
<!-- help popover template -->
<ng-template #helpStrTemplate>
  <span attr.aria-labelledby="help-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Instruction for {{item.question}}"
        attr.aria-describedby="help-content-{{item._elementId}}"
        id="help-content-{{item._elementId}}"
  >{{item.codingInstructions}}</span>
</ng-template>

<!-- legal popover template with trusted html -->
<ng-template #legalHtmlTemplate>
  <span attr.aria-labelledby="legal-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Legal for {{item.question}}"
        attr.aria-describedby="legal-content-{{item._elementId}}"
        id="legal-content-{{item._elementId}}"
        [innerHTML]="item._legalWithContainedImages || item.legal | safeHtml"
  ></span>
</ng-template>
<!-- legal popover template with plain text-->
<ng-template #legalPlainTemplate>
  <span attr.aria-labelledby="legal-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Legal for {{item.question}}"
        attr.aria-describedby="legal-content-{{item._elementId}}"
        id="legal-content-{{item._elementId}}"
  >{{item.legalPlain}}</span>
</ng-template>
<!-- legal popover template -->
<ng-template #legalStrTemplate>
  <span attr.aria-labelledby="legal-button-{{item._elementId}}"
        role="region"
        attr.aria-label="Legal for {{item.question}}"
        attr.aria-describedby="legal-content-{{item._elementId}}"
        id="legal-content-{{item._elementId}}"
  >{{item.legal}}</span>
</ng-template>


<!-- copyright popover template -->
<ng-template #copyrightStrTemplate >
  <span *ngIf="formLevel"
        id="copyright-content-{{popoverIdentifier}}"
        attr.aria-labelledby="copyright-button-{{popoverIdentifier}}"
        role="region"
        attr.aria-label="Copyright notice for {{item.name || item.shortName}}"
        attr.aria-describedby="copyright-content-{{popoverIdentifier}}"
        id="copyright-content-{{popoverIdentifier}}"
  >{{item.copyrightNotice}}</span>
</ng-template>
