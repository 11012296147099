<ng-container *ngIf="item">
  <div class="lhc-layout-horizontal {{lhcDataService.getSiblingStatus(item)}} "
    *ngIf="item._horizontalTableHeader && lhcDataService.getHorizontalTableInfo()[item._horizontalTableId]">
    <div ng-attr-role="{{item.header ? 'heading' : undefined}}"
        ng-attr-aria-level="{{item.header ? item._displayLevel+1 : undefined}}"
        class="lhc-form-horizontal-table-title" *ngIf="!formLevel">
      <lhc-item-question-text [item]="item" ></lhc-item-question-text>
    </div>

    <table class="lhc-form-horizontal-table">
      <colgroup>
        <col class="lhc-de-button" *ngIf="item._questionRepeatable && lhcDataService.getHorizontalTableInfo()[item._horizontalTableId].tableRows.length>1">
        <ng-container *ngFor="let col of lhcDataService.getHorizontalTableInfo()[item._horizontalTableId].columnHeaders; trackBy lhcDataService.trackByColumnHeaderId">
          <col *ngIf="!col.item._isHiddenFromView" [ngStyle]="lhcDataService.getTableColumnStyle(col)">
        </ng-container>
      </colgroup>
      <thead>
      <tr>
        <th class="lhc-form-horizontal-table-header" *ngIf="item._questionRepeatable && lhcDataService.getHorizontalTableInfo()[item._horizontalTableId].tableRows.length>1"></th>
        <ng-container *ngFor="let col of lhcDataService.getHorizontalTableInfo()[item._horizontalTableId].columnHeaders; trackBy lhcDataService.trackByColumnHeaderId">
          <th *ngIf="!col.item._isHiddenFromView" class="lhc-form-horizontal-table-header" id="{{col.id}}">
            <lhc-item-question-text [item]="col.item" ></lhc-item-question-text>
          </th>
        </ng-container>
      </tr>
      </thead>
      <tbody id="" class="">
      <tr *ngFor="let row of lhcDataService.getHorizontalTableInfo()[item._horizontalTableId].tableRows; trackBy: lhcDataService.trackByRowHeaderElementId"
          class="lhc-data-row has-ng-animate">
        <td class="lhc-de-button" *ngIf="item._questionRepeatable && lhcDataService.getHorizontalTableInfo()[item._horizontalTableId].tableRows.length>1">
          <button *ngIf="!lhcDataService.hasOneRepeatingItem(item)" type="button"
                  id="del-{{row.header._elementId}}" [disabled]="item._readOnly"
                  class="lhc-float-button lhc-button" (click)="lhcDataService.removeOneRepeatingItem(row.header)"
                  title='Remove this row of "{{ row.header.question }}"'>-</button>
        </td>

        <ng-container *ngFor="let cell of row.cells; trackBy: lhcDataService.trackByElementId">
          <td *ngIf="!cell._isHiddenFromView"
              class="{{lhcDataService.getRowClass(cell)}} {{lhcDataService.getSkipLogicClass(cell)}} {{lhcDataService.getActiveRowClass(cell)}}">
            <div class="lf-form-item-data tooltipContainer">
              <lhc-item-question [item]="cell" [options]="{hideQuestionText: true}" class="lhc-de"></lhc-item-question>
            </div>
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>

    <!-- a button at the end of each repeating section -->
    <div *ngIf="item._questionRepeatable && lhcDataService.targetEnabled(item) && !item._isHiddenFromView" class="lhc-button-padding {{lhcDataService.getSkipLogicClass(item)}}">
      <lhc-button-popover [item]="item" [popoverType]="'repeating-item'" buttonLabel= '+ {{item._text}}' [append]="true"></lhc-button-popover>
    </div>
  </div>

</ng-container>
